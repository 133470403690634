
/* thin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(fonts/Roboto-Thin.ttf) format('truetype');
}

/* thin-italic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(fonts/Roboto-ThinItalic.ttf) format('truetype');
}

/* light */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(fonts/Roboto-Light.ttf) format('truetype');
}

/* light-italic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(fonts/Roboto-LightItalic.ttf) format('truetype');
}

/* regular */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(fonts/Roboto-Regular.ttf) format('truetype');
}

/* regular-italic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(fonts/Roboto-Italic.ttf) format('truetype');
}

/* medium */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(fonts/Roboto-Medium.ttf) format('truetype');
}

/* medium-italic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(fonts/Roboto-MediumItalic.ttf) format('truetype');
}

/* bold */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(fonts/Roboto-Bold.ttf) format('truetype');
}

/* bold-italic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(fonts/Roboto-BoldItalic.ttf) format('truetype');
}

/* extra-bold */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(fonts/Roboto-Black.ttf) format('truetype');
}

/* extra-bold-italic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(fonts/Roboto-BlackItalic.ttf) format('truetype');
}

